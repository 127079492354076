import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}


const GlobalStyle = createGlobalStyle`
    @font-face {
      font-family: comicon;
      src: url('/font.woff');
    }

  * {
   // font-family: 'Kanit', sans-serif;
    font-family: comicon;
   

  }
  body {
    background-image: url('/gif.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;

    @media only screen and (max-width: 600px) {
      background-size: auto 100%;
    }
  }

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
